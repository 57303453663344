export type MergeComponentProps<
  Root extends React.ElementType,
  Props
> = Omit<React.ComponentProps<Root>, keyof Props> & Props

export function makeClassName (payload: any) : string {
  if (!payload) return ''
  if (Array.isArray(payload)) {
    return payload
      .map(makeClassName)
      .filter(Boolean)
      .join(' ')
  }
  if (typeof payload === 'object') {
    return Object.keys(payload)
      .filter((k) => payload[k])
      .join(' ')
  }
  return String(payload)
}

export function convertToUnit (value: any, unit = 'px') {
  if (value == null || value === '') {
    return undefined
  } else if (isNaN(+value)) {
    return String(value)
  } else {
    return `${Number(value)}${unit}`
  }
}

export function emitEvent (el: HTMLElement | null | undefined, name: string, data?: any) {
  const event = new CustomEvent(name, { composed: true, detail: data })
  el?.dispatchEvent(event)
}

export type fetchHandlerProps = {
  url: string,
  method?: 'GET' | 'POST',
  body?: object,
  headers?: object,
  language?: string
}

export async function $fetch ({
  url,
  method = 'GET',
  body,
  headers,
  language
}: fetchHandlerProps) {
  const res = await fetch(url, {
    headers: {
      accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'accept-language': language || '',
      ...headers
    },
    method,
    body: body ? JSON.stringify(body) : undefined,
    credentials: 'include'
  })
  if (res.status !== 200) return { code: res.status }
  try {
    const data = await res.json()
    return { ...data, code: 200 }
  } catch {
    return { data: res.body, code: 200 }
  }
}

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export function toKebabCase (str: string) {
  return str
    .replace(/([A-Z]|[0-9]+)/g, '-$1')
    .replace(/[\s-]+/gi, '-')
    .replace(/[^a-z0-9-_]/gi, '')
    .replace(/^-/, '')
    .toLowerCase()
}
export const URL_BASE = 'https://public-storage.farmacloud.io/applications/top-bar-config/'

export type Environment = 'dev' | 'pro'

export type Theme = 'light' | 'dark'

export type TopbarMenuItem = {
  type: 'event' | 'menu'
  text: string
  eventName?: string
  iconLeft?: string
  iconRight?: string
  tag?: string
  disabled?: boolean,
  active?: boolean,
  closeOnClick?: boolean,
  menuWidth?: number | string,
  options?: TopbarMenuItem[]
}

export type TopbarMenu = {
  text?: string
  version?: string,
  theme?: Theme,
  menuWidth?: number | string,
  options: TopbarMenuItem[]
} | null | undefined

import React, { useRef, useMemo } from 'react'
import { Btn, Text, Icon } from '../index'
import type { BtnProps } from '../Btn'

export type DropdownItemProps = BtnProps & {
  id?: string,
  prependIcon?: string | JSX.Element,
  appendIcon?: string | JSX.Element,
  text?: string,
  tag?: string,
  disabled?: boolean
}

export const DropdownItem = React.forwardRef(function DropdownItem ({
  id,
  className,
  prependIcon,
  appendIcon,
  text,
  tag,
  disabled,
  ...props
} : DropdownItemProps, ref) {

  const internalRef = useRef<HTMLSpanElement | null>(null)
  const refGetter = (node: HTMLSpanElement | null) => {
    internalRef.current = node;
    if (typeof ref === 'function') ref(node)
    else if (ref) ref.current = node
  }

  const PrependIcon = useMemo(() => {
    if (!prependIcon) return null
    if (typeof prependIcon === 'string') {
      return <Icon name={prependIcon} className="prepend-icon text-base" size="20"/>
    }
    return prependIcon
  }, [prependIcon])

  const AppendIcon = useMemo(() => {
    if (!appendIcon) return null
    if (typeof appendIcon === 'string') {
      return <Icon name={appendIcon} className="append-icon text-base" size="20"/>
    }
    return appendIcon
  }, [appendIcon])

  const Tag = useMemo(() => {
    if (!tag) return null
    return (
      <Text
        className="rounded-pill px-3 py-1 mx-2"
        weight="bold"
        bgColor="primary"
        color="white"
        size="sm"
      >
        {tag}
      </Text>
    )
  }, [tag])

  return (
    <Btn
      ref={refGetter}
      id={id ? `dropdown-item-${id}` : ''}
      className={['topbar-dropwdown-item px-4', className]}
      variant="tonal"
      color="base"
      weight="normal"
      block={true}
      disabled={disabled}
      {...props}
    >
      {PrependIcon}
      {text}
      {Tag}
      {AppendIcon}
    </Btn>
  )
}) 